import React from "react";
import { Navbar } from '../components';

const Satisfaction = () => {
    const containerStyle = {
        backgroundColor: "black",
        color: "white",
        padding: "5vw", // Using vw for dynamic spacing
        minHeight: "100vh",
        fontFamily: "var(--font-base)", // Custom font from your website
    };

    const contentStyle = {
        maxWidth: "80vw", // Dynamic width based on viewport
        margin: "0 auto", // Center the content
        padding: "2vw", // Dynamic padding
    };

    const headerStyle = {
        textAlign: "center", // Center the header text
        fontSize: "3vw", // Dynamic font size for header
    };

    const buttonStyle = {
        backgroundColor: "var(--color-golden)", // Your website's button color
        color: "black",
        padding: "1vw 3vw", // Dynamic button padding
        border: "none",
        cursor: "pointer",
        marginTop: "3vw", // Dynamic margin
        textDecoration: "none",
        display: "inline-block",
        fontSize: "1.5vw", // Dynamic button text size
    };

    return (
        <>
        <Navbar/>
        <div style={containerStyle}>
        <header style={headerStyle}>
            <h1>Garantie de satisfaction totale de Distillerie Cap-Chat</h1>
        </header>
        <main>
            <section style={contentStyle}>
            <p>
                (English version below) <br />
                Nous vous offrons une expérience sublime dans chaque bouteille. C’est notre engagement de qualité et notre raison d’être.<br /><br />
                Si vous avez l’impression que l’un de nos produits n’est pas excellent à tout point de vue selon votre goût personnel, rapportez la bouteille à la boutique de la Distillerie Cap-Chat et repartez avec un autre spiritueux de votre choix et de même valeur gratuitement.<br /><br />
                -Aucune facture nécessaire lorsque vous rapportez une bouteille.<br />
                -Processus rapide sans aucun tracas.<br />
                -Maximum de 2 bouteilles ouvertes par personne.<br />
                -Uniquement pour les bouteille vendue il y a moins d’une année (no. de traçabilité sur chaque bouteille)<br />
                -Le produit d’origine doit encore être dans la bouteille avec son % d’alcool initial.<br />
                -La bouteille doit être encore au moins au 3/4 pleine.<br />
                -Valable uniquement à la boutique de la Distillerie Cap-Chat (16 rue Notre-Dame Est, Cap-Chat, Québec).<br />
                -Aucune bouteille éligible si reçue par la poste.<br />
                -Aucune limite de retour et de remboursement pour les bouteilles non ouvertes.<br /><br />
                Dans tous les cas, c’est toujours un immense plaisir de vous recevoir à la boutique et nous espérons vous servir pour de nombreuses années.<br /><br />
                À votre santé!<br /><br />
            </p>

            <h1>Total satisfaction guarantee from Distillerie Cap-Chat</h1>
            <p>
                We offer you a sublime experience in every bottle. This is our commitment to quality and our purpose.<br /><br />
                If you have the impression that one of our products is not excellent in every way according to your personal taste, return the bottle to the Distillerie Cap-Chat’s store and leave with another spirit of your choice and of the same value for free.<br /><br />
                -No invoice needed when you return a bottle. <br />
                -Quick process without any hassle. <br />
                -Maximum of 2 open bottles per person.<br />
                -Only for bottles sold less than a year ago (traceability number on each bottle)<br />
                -The original product must still be in the bottle with its original alcohol percentage.<br />
                -The bottle must still be at least 3/4 full.<br />
                -Valid only at the Distillerie Cap-Chat’s store (16 rue Notre-Dame Est, Cap-Chat, Québec).<br />
                -No bottles eligible if received by mail.<br />
                -No limit of bottles when they are not opened yet.<br /><br />
                In any case, it is always a great pleasure to welcome you to the store and we hope to serve you for many years.<br /><br />
                Cheers!<br /><br />
            </p>

            <a href="/" style={buttonStyle}>
                Retour
            </a>
            </section>
        </main>
        <footer style={contentStyle}>
            <p>© 2024 Distillerie Cap-Chat. Tous droits réservés.</p>
        </footer>
        </div>
        </>
    );
};

export default Satisfaction;
